import * as React from 'react'

export function XIconColor({ title, height = 24, width = 24, ...props }) {
  return (
    <div style={{ width, height }}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" width="100%" height="100%" viewBox="0 0 24 24" {...props}>
        <title>{title}</title>
        <path
          d="M13.969 10.1571L22.7069 0H20.6363L13.0491 8.81931L6.9893 0H0L9.16366 13.3364L0 23.9877H2.07073L10.083 14.6742L16.4826 23.9877H23.4719L13.9684 10.1571H13.969ZM11.1328 13.4538L10.2043 12.1258L2.81684 1.55881H5.99736L11.9592 10.0867L12.8876 11.4147L20.6373 22.4998H17.4567L11.1328 13.4544V13.4538Z"
          fill="#000000"
        />
      </svg>
    </div>
  )
}

export default XIconColor 