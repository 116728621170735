import { get as _get } from 'lodash-es'
import footerNavigation from 'components/Footer/footerNavigation.json'
import { Footer } from 'components/Footer/UniversalFooter/Footer/Footer'
import { useAnalyticsTrackEvent } from 'hooks/useAnalyticsTrackEvent'
import { Typography, TypographyLongForm } from 'components/Contentful'
import { MediaImageStandard } from 'components/Contentful'
import { FdrTheme, AchieveTheme, AscendThemeProvider } from '@achieve/ascend'
import { GlobalContext } from 'providers/GlobalProvider'
import { useContext } from 'react'
import { LayoutContext } from 'providers/LayoutProvider'
import SimpleFooter from 'components/Footer/SimpleFooter/SimpleFooter'
import SITE_THEME from 'constants/websites'

let theme
switch (process.env.NEXT_PUBLIC_SITE_ENVIRONMENT) {
  case SITE_THEME.ACHIEVE:
    theme = AchieveTheme
    break
  case SITE_THEME.FDR:
    theme = FdrTheme
    break
  default:
    theme = AchieveTheme
}

export default function FooterContainer({
  disabledRoutes,
  content,
  secondaryDisclosures,
  showNav,
  hiddenNavSection = [],
  showFooterSocialIcons,
}) {
  const { analyticsTrackEvent } = useAnalyticsTrackEvent()
  const { state: { siteEnv, siteInfo } = {} } = useContext(LayoutContext)
  const finalShowNav = typeof showNav == 'boolean' ? showNav : siteInfo?.showNavFooter
  const { pageDisclosure } = useContext(GlobalContext)
  let footerMenu = footerNavigation?.[siteEnv || 'achieve']?.footerMenus || []
  const brandCopy = _get(content, 'logoHelpText') || siteInfo?.footerBrandCopy
  const menuContent = _get(content, 'footerItems')
  const jsonConfig = _get(content, 'config')
  const uiComponent = _get(content, 'uiComponent', 'DefaultFooter')
  const markersResources = _get(content, 'markers') ?? footerNavigation.markers
  const copyrightMessage = _get(
    content,
    'copyrightMessage.content[0].content[0].value',
    `${siteInfo?.url}. All rights reserved.`
  )

  const footerSchema = {
    '@context': 'https://schema.org',
    '@type': 'WebApplication',
    applicationCategory: jsonConfig?.schema?.applicationCategory || '',
    applicationSubCategory: jsonConfig?.schema?.applicationSubCategory || '',
    name: siteInfo?.mainName,
    aggregateRating: {
      reviewCount: [Number(jsonConfig?.schema?.reviewCount?.replace(/,/g, '')) || 0],
      ratingValue: [Number(jsonConfig?.schema?.ratingValue?.replace(/,/g, '')) || 0],
      bestRating: 5,
    },
    offers: {
      '@type': jsonConfig?.schema?.offersType,
      price: jsonConfig?.schema?.offetPrice,
      priceCurrency: jsonConfig?.schema?.offetPriceCurrency,
    },
    operatingSystem: 'All',
    copyrightYear: new Date().getFullYear(),
    description: brandCopy,
  }

  const footerOrganizationSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: siteInfo?.mainName,
    alternateName: siteInfo?.url,
    url: siteInfo?.publicUrl,
    logo: { '@type': 'ImageObject', url: siteInfo?.logoUrl },
    image: siteInfo?.image,
    description: siteInfo?.description,
    sameAs: siteInfo?.socialMediaLinks,
    address: {
      '@type': 'PostalAddress',
      streetAddress: siteInfo?.streetAddress,
      addressLocality: siteInfo?.addressLocality,
      addressRegion: siteInfo?.addressRegion,
      postalCode: siteInfo?.postalCode,
      addressCountry: { '@type': 'Country', name: 'US' },
    },
    telephone: siteInfo?.telephone || '',
    contactPoint: [
      {
        '@type': 'ContactPoint',
        type: 'ContactPoint',
        telephone: siteInfo?.postalCode,
        contactType: 'customer service',
        areaServed: ['US'],
        availableLanguage: siteInfo?.availableLanguage,
      },
    ],
  }

  if (menuContent) {
    footerMenu = []
    for (let i = 0; i < menuContent.length; i++) {
      const element = menuContent[i]
      const subMenu = []
      for (let j = 0; j < element?.fields?.gridItems.length; j++) {
        const subElement = element?.fields?.gridItems[j]
        const label = _get(subElement, 'fields.linkText.content[0].content[0].value')
        const name = _get(subElement, 'fields.name')
        const url = _get(subElement, 'fields.linkHref')
        if (!disabledRoutes.includes(url) && url?.length) {
          subMenu.push({
            label: label || name,
            url,
          })
        }
      }
      if (subMenu.length) {
        const menuLabel = _get(element, 'fields.name')
        if (!hiddenNavSection.includes(menuLabel))
          footerMenu.push({
            menuLabel: menuLabel,
            menuItems: subMenu,
          })
      }
    }
  }

  if (uiComponent === 'SimpleFooter') {
    return (
      <SimpleFooter
        footerNavigation={footerMenu}
        pageDisclosure={[pageDisclosure, ...secondaryDisclosures]?.map((dataDisclosure, index) => (
          <div key={index}>
            <TypographyLongForm content={dataDisclosure} variant="bodyXs" fontWeight="regular" />
          </div>
        ))}
        copyrightMessage={copyrightMessage}
      />
    )
  }

  return (
    <AscendThemeProvider theme={theme}>
      <Footer
        mainName={siteInfo?.mainName}
        footerNavigation={footerMenu}
        pageDisclosure={[pageDisclosure, ...secondaryDisclosures]?.map((dataDisclosure, index) => (
          <div key={index}>
            <TypographyLongForm content={dataDisclosure} variant="bodyXs" fontWeight="regular" />
          </div>
        ))}
        markersSection={markersResources?.map((marker, index) => {
          return (
            <div key={`marker-img-${index}`}>
              <MediaImageStandard
                alt={marker.fields.name}
                content={marker.fields.image}
                width={90}
                height={60}
                objectFit="contain"
              />
              {marker.fields.subtitle !== '.' && (
                <Typography
                  content={marker.fields.subtitle}
                  variant="bodyXs"
                  fontWeight="regular"
                  style={{ textAlign: 'center', marginTop: -2 }}
                  fontSize="8px"
                />
              )}
            </div>
          )
        })}
        logoImage={siteInfo?.footerLogo}
        brandCopy={brandCopy}
        trackingCB={analyticsTrackEvent}
        copyrightMessage={copyrightMessage}
        showNav={finalShowNav}
        showFooterSocialIcons={showFooterSocialIcons}
        backgroundColor={
          AchieveTheme?.backgroundColors?.primary || AchieveTheme?.ascend.colors?.blue[50]
        }
        schema={jsonConfig?.schema}
      />
      <script
        id="review"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(footerSchema) }}
      />
      <script
        id="organization"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(footerOrganizationSchema) }}
      />
    </AscendThemeProvider>
  )
}
