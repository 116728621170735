import { IconButton } from '@achieve/ascend';
import { ChevronUp } from 'react-feather';

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export function ScrollToTopButton(props) {
  return (
    <IconButton
      aria-label="Scroll to the top of the page"
      onClick={scrollToTop}
      {...props}
    >
      <ChevronUp />
    </IconButton>
  );
}