import { Typography, Grid, Container } from '@achieve/ascend'
import { ScrollToTopButton } from 'components/Footer/UniversalFooter/ScrollToTopButton/ScrollToTopButton'
import SocialIcons from 'components/Footer/UniversalFooter/SocialIcons/SocialIcons'
import * as styles from './Footer.module.scss'
import { getSlug } from 'utils/conversions'
import { LayoutContext } from 'providers/LayoutProvider'
import { useContext } from 'react'

const Footer = ({
  footerNavigation,
  pageDisclosure,
  logoImage,
  brandCopy,
  trackingCB,
  copyrightMessage,
  showNav = true,
  showFooterSocialIcons = true,
  logoLink = true,
  showMarkers = true,
  markersSection = [],
  mainName,
  schema = {},
}) => {
  const currentYear = new Date().getFullYear()
  const { state: { siteEnv } = {} } = useContext(LayoutContext)

  return (
    <footer
      className={styles.footer}
      data-show-nav={showNav ? 'true' : 'false'}
      data-testid="footer"
      data-site={siteEnv}
    >
      <Container
        maxWidth={false}
        disableGutters
        className={styles['footer-content-container']}
        data-narrow="true"
      >
        {showNav && (
          <div className={styles['footer-row']}>
            {footerNavigation.map(({ menuLabel, menuItems = [] }, menuIndex) => {
              return (
                <nav
                  className={styles['footer-section']}
                  key={menuIndex}
                  data-testid="footer-menus"
                >
                  <Typography
                    component="h3"
                    fontWeight="bold"
                    variant="labelSm"
                    data-testid="footer-menu-title"
                  >
                    {menuLabel}
                  </Typography>
                  <ul>
                    {menuItems.map(({ label, testid, url, target = '_self' }, menuItemIndex) => (
                      <li key={menuItemIndex} data-testid={testid}>
                        <a
                          data-testid={`footer-menu-${menuItemIndex}`}
                          href={url}
                          target={target}
                          onClick={() =>
                            trackingCB &&
                            trackingCB({
                              nav_link_section: 'Footer Click',
                              click_type: 'Link Click',
                              click_id: label,
                              track_event: 'global_footer',
                              event_type: 'link_click',
                            })
                          }
                          className={styles['footer-link']}
                        >
                          <Typography variant="labelSm" fontWeight="regular">
                            {label}
                          </Typography>
                        </a>
                      </li>
                    ))}
                  </ul>
                </nav>
              )
            })}
            <div className={styles['footer-section-logo']} data-testid="footer-menus">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className={styles['footer-brand-icon-row']}
              >
                <Grid item>
                  {logoLink ? (
                    // eslint-disable-next-line @next/next/no-html-link-for-pages
                    <a
                      className={styles['footer-logo']}
                      href="/"
                      onClick={() =>
                        trackingCB &&
                        trackingCB({
                          nav_link_section: 'Footer',
                          click_type: 'Link Click',
                          click_id: logoImage,
                          track_event: 'global_footer',
                          event_type: 'link_click',
                        })
                      }
                      data-testid={`${getSlug(mainName)}-logo`}
                      aria-label={mainName}
                    >
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        src={logoImage ? logoImage : 'src/assets/2022_Achieve_Logomark_RGB.svg'}
                        alt={`${mainName} Logomark`}
                        loading="lazy"
                      />
                    </a>
                  ) : (
                    <div className={styles['footer-logo']}>
                      {/* eslint-disable-next-line @next/next/no-img-element */}
                      <img
                        src={logoImage ? logoImage : 'src/assets/2022_Achieve_Logomark_RGB.svg'}
                        alt={`${mainName} Logomark`}
                        loading="lazy"
                      />
                    </div>
                  )}
                </Grid>
                <Grid item>
                  <ScrollToTopButton
                    data-testid="scroll-to-top-button"
                    className={styles['scroll-to-top']}
                  />
                </Grid>
              </Grid>
              <Typography
                component="p"
                variant="bodyXs"
                fontWeight="regular"
                className={styles['text-copy']}
              >
                {brandCopy ||
                  `Achieve is the leader in digital personal finance, built to help everyday people move forward on the path to a better financial future.`}
              </Typography>
              {showFooterSocialIcons && (
                <Grid item className={styles['footer-social-links-row']}>
                  <SocialIcons items={schema?.oganizationSocialMediaLinks} trackingCB={trackingCB} />
                </Grid>
              )}
              {markersSection?.length > 0 && showMarkers && (
                <Grid container gap={3} alignItems="center" className={styles['markers-grid']}>
                  {markersSection.map((item, index) => (
                    <Grid
                      item
                      key={`marker-footer-${index}`}
                      position="relative"
                      className={styles['markers-grid-item']}
                    >
                      {item}
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>
          </div>
        )}

        {pageDisclosure?.map((dataDisclosure, index) => {
          if (!dataDisclosure) return
          return (
            <div
              key={'dislosure-' + index}
              className={styles['footer-disclosure']}
              data-testid="footer-page-disclosure"
            >
              {dataDisclosure}
            </div>
          )
        })}
        <div className={styles['footer-copyright-row']}>
          <Grid
            container
            justifyContent="space-between"
            className={styles['footer-social-links-container']}
          >
            <Grid item>
              <Typography
                variant="bodyXs"
                fontWeight="regular"
                className={styles['footer-copyright']}
                data-testid="footer-copyright"
              >
                &copy; {currentYear} {copyrightMessage}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </Container>
    </footer>
  )
}

export { Footer }
