import { Grid } from '@achieve/ascend'
import FacebookIconGray from 'components/Svgs/FacebookIconGray'
import FacebookIconColor from 'components/Svgs/FacebookIconColor'
import InstagramIconGray from 'components/Svgs/InstagramIconGray'
import InstagramIconColor from 'components/Svgs/InstagramIconColor'
import XIconGray from 'components/Svgs/XIconGray'
import XIconColor from 'components/Svgs/XIconColor'
import YoutubeIconGray from 'components/Svgs/YoutubeIconGray'
import YoutubeIconColor from 'components/Svgs/YoutubeIconColor'
import LinkedInIconGray from 'components/Svgs/LinkedInIconGray'
import LinkedInIconColor from 'components/Svgs/LinkedInIconColor'
import * as styles from './SocialIcons.module.scss'

const keywords = ['instagram', 'facebook', 'x', 'youtube', 'linkedin', 'twitter'];

function findKeyword(text, keywords) {
  return keywords.find(keyword => text.includes(keyword.toLowerCase())) || null;
}

function FacebookComponent() {
  return (
    <div className={styles['icon-container']}>
      <div className={styles['icon-gray']}>
        <FacebookIconGray alt="Facebook" height={24} width={24} />
      </div>
      <div className={styles['icon-color']}>
        <FacebookIconColor alt="Facebook" height={24} width={24} />
      </div>
    </div>
  )
}

function InstagramComponent() {
  return (
    <div className={styles['icon-container']}>
      <div className={styles['icon-gray']}>
        <InstagramIconGray alt="Instagram" height={24} width={24} />
      </div>
      <div className={styles['icon-color']}>
        <InstagramIconColor alt="Instagram" height={24} width={24} />
      </div>
    </div>
  )
}

function XComponent() {
  return (
    <div className={styles['icon-container']}>
      <div className={styles['icon-gray']}>
        <XIconGray alt="X" height={24} width={24} />
      </div>
      <div className={styles['icon-color']}>
        <XIconColor alt="X" height={24} width={24} />
      </div>
    </div>
  )
}

function YouTubeComponent() {
  return (
    <div className={styles['icon-container']}>
      <div className={styles['icon-gray']}>
        <YoutubeIconGray alt="Youtube" height={24} width={24} />
      </div>
      <div className={styles['icon-color']}>
        <YoutubeIconColor alt="Youtube" height={24} width={24} />
      </div>
    </div>
  )
}

function LinkedInComponent() {
  return (
    <div className={styles['icon-container']}>
      <div className={styles['icon-gray']}>
        <LinkedInIconGray alt="Linkedin" height={24} width={24} />
      </div>
      <div className={styles['icon-color']}>
        <LinkedInIconColor alt="Linkedin" height={24} width={24} />
      </div>
    </div>
  )
}

function SocialRenderer(arg) {
  const match = findKeyword(arg, keywords);

  switch (match) {
    case 'facebook':
      return <FacebookComponent />;
    case 'instagram':
      return <InstagramComponent />;
    case 'x':
      return <XComponent />;
    case 'twitter':
      return <XComponent />;
    case 'youtube':
      return <YouTubeComponent />;
    case 'linkedin':
      return <LinkedInComponent />;
    default:
      return null;
  }
}

function AnchorTrack(props) {
  return (
    <a
      onClick={() => {
        props.trackingCB && props.trackingCB({ href: props.href })
      }}
      href={props.href}
      className={props.className}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </a>
  )
}

function SocialIcons(props) {
  const { items } = props
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      spacing={2}
      className={styles['footer-social-icons-container']}
      data-testid="footer-social-links-row"
    >
      {items?.map((item) => {
        const Comp = SocialRenderer(item)
        if (!Comp) return null
        return (
          <Grid key={item} item>
            <AnchorTrack
              href={item}
              className={styles['footer-social-icon']}
              trackingCB={props.trackingCB}
              ariaLabel="Facebook"
            >
              {Comp}
            </AnchorTrack>
          </Grid>
        )
      })}
    </Grid>
  )
}

export default SocialIcons
